<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>

      <data-view-sidebar :promptName="promptName" :isSidebarActive="displayPrompt" @closeSidebar="toggleDataSidebar" :formData="formData" />

        <vs-table ref="table" pagination :max-items="this.$store.state.pps" search :data="offers">
            
            <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

              <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">

                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon">
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </button>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Delete</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Archive</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Another Action</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>

                <!-- ADD NEW -->
                <div class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="addDataPrompt()">
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                      <span class="ml-2 text-base text-primary">Add New</span>
                  </button>
                </div>
                <!-- REFRESH -->
                <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
                  <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">Refresh</span>
                </button>
              </div>
            </div>

            <template slot="thead">
                <vs-th class="add_th" sort-key="offer_id">S.NO</vs-th>
                <vs-th class="add_th" sort-key="category_id">Category</vs-th>
                <vs-th class="add_th" sort-key="title">Title</vs-th>
                <vs-th class="add_th" sort-key="address"> Address </vs-th>
                <vs-th class="add_th" sort-key="website">Website</vs-th>
                <vs-th class="add_th" sort-key="created_at">Date</vs-th>
                <vs-th class="add_th" sort-key="status">Status</vs-th>
                <vs-th class="add_th">Action</vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                     <vs-td :data="data[indextr].first_name">
                        {{indextr+1}}
                    </vs-td>

                    <vs-td :data="data[indextr].category_id">
                        {{category_name(data[indextr].category_id)}}
                    </vs-td>

                    <vs-td :data="data[indextr].title">
                        {{data[indextr].title}}
                    </vs-td>

                    <vs-td :data="data[indextr].address">
                        {{data[indextr].address}}
                    </vs-td>

                    <vs-td :data="data[indextr].website">
                        {{data[indextr].website}}
                    </vs-td>

                    <vs-td :data="data[indextr].created_at">
                        {{date_format(data[indextr].created_at)}}
                    </vs-td>

                    <vs-td :data="data[indextr].status">
                        <vs-chip v-if="data[indextr].status==0" :color="'#d9534f'" class="product-order-status">InActive</vs-chip>
                        <vs-chip v-if="data[indextr].status==1" :color="'#5cb85c'" class="product-order-status">Active</vs-chip>
                        <vs-chip v-if="data[indextr].status==2" :color="'#d9534f'" class="product-order-status">Expired</vs-chip>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                      <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete" @click.stop="editDataPrompt(tr)" />
                      <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete" class="ml-2" @click="confirmDeleteRecord(tr.offer_id,data[indextr].title)" />
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

    </vx-card>
</template>

<style>
.file_container{
  position: relative;
  height: 100px;
  width: 200px;

}
input[type='file']{
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  border:1px dashed rgba(0,0,0,.1);
  /*border-radius:8px;*/
  /*padding: 0 0 0 200px;*/

}
.fb{
  position: absolute;
  bottom: 55%;left: 45%;
}

</style>

<script>
// import moduleOffer          from '@/store/offer/moduleOffer.js'
import DataViewSidebar from './DataViewSidebar.vue'
import vSelect from 'vue-select'
import axios from '@/axios.js'

export default {
  data () {
    return {
      promptName           : 'Add New Offer',
      formData             : {},
      displayPrompt        : false,
    }
  },
  components: {
    vSelect,
    DataViewSidebar
  },
  computed: {
    offers ()     { 
      return this.$store.getters['offer/queriedOffers']      
    },
    categories ()     { 
      return this.$store.getters['category/getAllMainCategories']
    },
    status_model: {
      get () {
        return this.formData['status'] === '1'
      },
      set (status) {
        this.formData['status'] = status ? '1' : '0'
      }
    },
    validateForm () {
      return !this.errors.any() && this.formData.name!="" && this.formData.document_url!=""
    }
  },
  methods: {
    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },
    toggleDataSidebar (val = false) {
      this.displayPrompt = val
    },
    refreshData () {
       this.$vs.loading()
      this.$store.dispatch('offer/fetchOffers').then(() => { this.$vs.loading.close() })
      this.$store.dispatch('category/fetchCategories')
    },
      category_name (categoryId) {
      return this.$store.getters['category/getCategoryName'](categoryId)
    },  
    addDataPrompt () {
      this.formData = {"title":"","image_url":"","org_id":this.$store.state.AppActiveUser.org_id,"status":false}
      this.promptName = "ADD NEW OFFER"
      this.toggleDataSidebar(true)
      localStorage.setItem('update_offer',0)
    },
    editDataPrompt (offer) {
      this.promptName = "UPDATE OFFER"
      this.toggleDataSidebar(true)
      localStorage.setItem('update_offer',1)
        this.add_image = false;
       this.remove_image = true;
      this.formData = Object.assign({}, offer)
    },
    confirmDeleteRecord (id,name) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You Are About To Delete Offer Title Of "${name}"`,
        accept: this.deleteOffer,
        acceptText: 'Confirm',
        parameters: id
      })
    },
    deleteOffer (parameters) {
      this.$vs.loading()
      this.$store.dispatch('offer/deleteOffer', parameters).then(() => {
                  this.$store.dispatch('offer/fetchOffers')
                  this.$vs.loading.close()
                  const error = "Offer Removed Successfully...!!!"
                  this.notif(error)     
       }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
                })
    },
       notif (error) {
      this.$vs.notify({
        title: 'Removed',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
  },
  
  created () {
    // this.$store.registerModule('offer', moduleOffer)

    if(this.offers.length==0){
       this.$vs.loading()
      this.$store.dispatch('offer/fetchOffers').then(() => { this.$vs.loading.close() })
    }
//    if(this.categories.length==0){
      this.$store.dispatch('category/fetchCategories')
//    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule('offer')
  }
}
</script>
