<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ promptName }}</h4>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
      <template>
          <label class="text-sm" style="margin-left: 4px;">Detail Image  <span class="text-danger"> * </span></label>

          <!-- Image Container -->
          <div class="img-container w-full mx-auto flex items-center justify-center" >
            <img :src="formData['image_url']||'/600x400.png'" alt="img" class="responsive" style="height: 168px;">
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5" v-if="add_thumbnail">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept=".png,.jpg,.jpeg">
             <vs-button class="mr-4" type="flat" v-if="add_thumbnail" @click="$refs.updateImgInput.click()">Update</vs-button>
          </div>

           <div class="modify-img flex justify-between mt-5" v-if="remove_thumbnails">
            <input type="file" class="hidden" ref="updateImgInput" @change="remove_image" accept=".png,.jpg,.jpeg">
             <vs-button class="mr-4" type="flat" v-if="remove_thumbnails" @click="$refs.updateImgInput.click()">Replace</vs-button>
          </div>
        </template>

 <template>
          <label class="text-sm" style="margin-left: 4px;">List Image  <span class="text-danger"> * </span></label>

          <!-- Image Container -->
          <div class="img-container w-full mx-auto flex items-center justify-center" >
            <img :src="formData['image_url_list']||'/400x400.png'" alt="img" class="responsive" style="height: 168px;">
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5" v-if="add_thumbnailList">
            <input type="file" class="hidden" ref="updateImgInputList" @change="updateCurrImgList" accept=".png,.jpg,.jpeg">
             <vs-button class="mr-4" type="flat" v-if="add_thumbnailList" @click="$refs.updateImgInputList.click()">Update</vs-button>
          </div>

           <div class="modify-img flex justify-between mt-5" v-if="remove_thumbnailsList">
            <input type="file" class="hidden" ref="updateImgInputList" @change="remove_imageList" accept=".png,.jpg,.jpeg">
             <vs-button class="mr-4" type="flat" v-if="remove_thumbnailsList" @click="$refs.updateImgInputList.click()">Replace</vs-button>
          </div>
        </template>

        <vs-input v-model="formData['title']" v-validate="'required'" :maxlength=40  label="Title" name="title" class="w-full mb-4 mt-5 required" placeholder="Title" />
        <span class="text-danger text-sm" v-show="errors.has('title')">{{ errors.first('title') }}</span>

        <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Category <span class="text-danger"> * </span></label>
            <v-select class="w-full select-large"   placeholder="Category Name" label="category_name" value="category_id" v-model="category_name_model" :clearable="false" :options="main_categories" v-validate="'required'" name="category_id" />
          </div>

          <div class="mt-4">
         <label class="text-sm" style="margin-left: 4px;">Contact Info <span class="text-danger"> * </span></label>
            <vs-textarea maxlength="240" rows="3" :maxlength=240  v-model="formData['contact_info']" placeholder="Contact Info" v-validate="'required'" name="contact_info" />
            <span class="text-danger text-sm"  v-show="errors.has('contact_info')">{{ errors.first('contact_info') }}</span>
        </div>

         <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">From Date  <span class="text-danger"> * </span></label>
            <flat-pickr v-model="from_date_model" :config="{enableTime: true, dateFormat: 'Y-m-d H:i' }" class="w-full" name="offer_start_time" />
            <span class="text-danger text-sm"  v-show="errors.has('offer_start_time')">{{ errors.first('offer_start_time') }}</span>
          </div>
          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">To Date  <span class="text-danger"> * </span></label>
            <flat-pickr v-model="to_date_model" :config="{enableTime: true, dateFormat: 'Y-m-d H:i' }" class="w-full"  name="offer_end_time" />
            <span class="text-danger text-sm"  v-show="errors.has('offer_end_time')">{{ errors.first('offer_end_time') }}</span>
             <span class="text-danger text-sm" v-if="errEndTime">To Date Must Always Greater Than From Date....!!!</span>
         
         </div>
      
         <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Address <span class="text-danger"> * </span></label>
            <vs-textarea rows="2" v-validate="'required'" :maxlength=240  name="address" v-model="formData['address']" placeholder="address..." />
            <span class="text-danger text-sm"  v-show="errors.has('address')">{{ errors.first('address') }}</span>
          </div>
     <!--   <vs-input v-model="formData['addr_latitude']" label="Adderss Latitude" v-validate="'required'" name="addr_latitude"  @keypress="isNumber_latitude($event)" class="w-full mb-4 mt-5 required" placeholder="Adderss Latitude" />
        <span class="text-danger text-sm" v-show="errors.has('addr_latitude')">{{ errors.first('addr_latitude') }}</span>

         <vs-input v-model="formData['addr_longitude']" label="Adderss Longitude" v-validate="'required'" name="addr_longitude" @keypress="isNumber_longitude($event)" class="w-full mb-4 mt-5 required" placeholder="Adderss Longitude" />
        <span class="text-danger text-sm" v-show="errors.has('addr_longitude')">{{ errors.first('addr_longitude') }}</span>
    -->
        <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Description <span class="text-danger"> * </span></label>
          <vs-textarea rows="3" v-model="formData['description']" placeholder="Description..." />
          <span class="text-danger text-sm"  v-show="errors.has('description')">{{ errors.first('description') }}</span>
        </div>

        <vs-input v-model="formData['website']" v-validate="'required'" label="Website" name="website" class="w-full mb-4 mt-5 required" placeholder="Website" />
        <span class="text-danger text-sm" v-show="errors.has('website')">{{ errors.first('website') }}</span>

        <div class="mt-8">
          <label class="text-sm" style="margin-left: 4px;">Status</label><br>
          <vs-switch color="success" v-model="status_model" style="width: 70px !important;">
              <span slot="on">Active</span>
              <span slot="off">InActive</span>
          </vs-switch>
        </div>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<style>
  #pickup_address{
    height: 37px;
    border:1px solid lightgrey;
    border-radius: 3px;
    padding: 0 8px 0 8px;
  }
</style>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    promptName: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    flatPickr
  },
  data () {
    return {
       add_image: true,
       remove_image : '',
       upload_image:0,
       errEndTime:'',
        remove_thumbnailsList : '',
      add_thumbnailList: true,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      autocompleteOptions: {
        componentRestrictions: {
          country: [
            'in',
          ],
        },
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
        this.add_image = true;
        this.formData['image_url'] = '/600x400.png' 
        this.remove_image = false;
        this.upload_image = 0
          this.$emit('closeSidebar')
          // this.$validator.reset()
        }
      }
    },
    from_date_model: {
      get () {
        return this.formData['offer_start_time']
      },
      set (date) {
        this.formData['offer_start_time'] = date
      }
    },
    to_date_model: {
      get () {
        return this.formData['offer_end_time']
      },
      set (date) {
         var toDate = new Date(Date.parse(date));
        var fromDate = new Date(Date.parse(this.formData['offer_start_time']));
        var startDay = new Date(toDate);
        var endDay = new Date(fromDate);
        var millisecondsPerDay = 1000 * 60 * 60 * 24;

        var millisBetween = startDay.getTime() - endDay.getTime();
        var days = millisBetween / millisecondsPerDay;
        var to_dd = toDate.getDate();
        var to_mm = toDate.getMonth();
        var to_y = toDate.getFullYear();

        var to_h = toDate.getHours();
        var to_m = toDate.getMinutes();
        var to_s = toDate.getSeconds();

        var from_dd = fromDate.getDate();
        var from_mm = fromDate.getMonth();
        var from_y = fromDate.getFullYear();

         var from_h = fromDate.getHours();
        var from_m = fromDate.getMinutes();
        var from_s = fromDate.getSeconds();
        var toFormattedDate = to_mm + '-' + to_dd + '-' + to_y;
        var fromFormattedDate = from_mm + '-' + from_dd + '-' + from_y;

       //if(new Date(toFormattedDate) < new Date(fromFormattedDate))
        // if( Math.floor(days) < -1)
        // { 
        //    this.errEndTime = 1
        // }else if((from_h > to_h) || (to_m < from_m ) ){
        //   this.errEndTime = 1
        // }else{
        //   this.errEndTime = 0
        // }

         if( Math.floor(days) < -1)
        { 
          this.errEndTime = 1
        }else{
          this.errEndTime = 0
        }

        if( Math.floor(days) == -1)
        { 
            if((from_h > to_h) || (to_m < from_m ) ){
              this.errEndTime = 1
            }
        }

        this.formData['offer_end_time'] = date
      }
    },
    status_model: {
      get () {
        return this.formData['status'] === '1'
      },
      set (status) {
        this.formData['status'] = status ? '1' : '0'
      }
    },
    main_categories () { 
      return this.$store.getters['category/queriedCategories_offers']
    },
    category_name_model: {
      get () {
        return this.$store.getters['category/getCategory'](this.formData['category_id'])
      },
      set (category_name) {
        this.formData['category_id'] = category_name['category_id']
      }
    },
  
    isFormValid () {
       const update_offer = localStorage.getItem('update_offer')
        // if(update_offer == 1){
        //   this.remove_image = true;
        //  this.upload_image = 1
        // this.add_image = false;
        // }else{
        //  this.add_image = true;
        // this.remove_image = false;
        // this.upload_image = 0
        // }
         if(update_offer == 1){
         this.remove_thumbnails = true;
        this.add_thumbnail = false;
        this.remove_thumbnailsList = true;
        this.add_thumbnailList = false;
        }else{
         this.add_thumbnail = true;
        this.remove_thumbnails = false;
        this.add_thumbnailList = true;
        this.remove_thumbnailsList = false;
        }
      return !this.errors.any() && this.errEndTime == 0  && this.formData['title'] !== '' && this.formData['category_id'] !== '' && this.upload_image == 1 && this.formData['org_id'] !== '' && this.formData['address'] !== '' && this.formData['description'] !== '' && this.formData['website'] !== '' && this.formData['title'] && this.formData['category_id'] && this.formData['org_id'] && this.formData['address'] && this.formData['description'] && this.formData['website']
    },
    scrollbarTag () {
        if (this.promptName == 'UPDATE OFFER') {
           this.add_image = true;
              this.remove_image = false;
            this.upload_image = 1
          } 
      
       return this.$store.getters.scrollbarTag }
  },
  methods: {
    submitData () {
     // this.$validator.validateAll().then(result => {
       // if (result) {
         this.$vs.loading()
          if (this.promptName === 'UPDATE OFFER') {
            this.$validator.validateAll().then(result => {
              if (result) {
                    this.$store.dispatch('offer/updateOffer', Object.assign({}, this.formData)).then((res) => {
                            this.$vs.loading.close()  
                            const success = 'Offer Updated Successfully...!!!'
                            this.notif_sucess(success) 
                    }).catch((error) => { 
                      if( error.message == "Request failed with status code 423"){
                          error.message = 'Offer is created by same name..!!!'
                      }else{
                          error.message = error.message
                      }
                                      this.$vs.loading.close()
                                      this.notif(error)     
                                    })
              }
            })
          } else {
            this.$validator.validateAll().then(result => {
              if (result) {
                this.$store.dispatch('offer/addOffer', Object.assign({}, this.formData)).then((res) => {
                    this.$vs.loading.close()  
                    const success = 'Offer added successfully...!!!'
                    this.notif_sucess(success) 
                  }).catch((error) => { 
                     if( error.message == "Request failed with status code 423"){
                          error.message = 'The offer name is already exist, please try with another name..!!!'
                      }else{
                          error = error.message
                      }
                                      this.add_image = true;
                                      this.remove_image = false;
                                      this.$vs.loading.close()
                                      this.notif(error)     
                                    })
              }
            })
          }
          this.$emit('closeSidebar')
        //}
      //})
    },
    setAddress (place) {
      this.currentPlace = place;
      this.formData['addr_latitude'] = place.geometry.location.lat();
      this.formData['addr_longitude'] = this.longitude_pickup = place.geometry.location.lng();
      this.formData['address'] = place.name + ", " + place.formatted_address;
    },
    isNumber_latitude: function($event) {
       const lat = JSON.stringify(this.formData['addr_latitude'])
         let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      // only allow number and one dot
       if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || lat.indexOf('.') != -1)) { // 46 is dot
        $event.preventDefault();
       }       
    },
      isNumber_longitude: function($event) {
         const long = JSON.stringify(this.formData['addr_longitude'])
         let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      // only allow number and one dot
       if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || long.indexOf('.') != -1)) { // 46 is dot
        $event.preventDefault();
       }       
    },
      notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
     notif_sucess (success) {
      this.$vs.notify({
        title: 'success',
        text: success,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"success"
      })
    },
      notif_failed (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"danger"
      })
    },
     updateCurrImgList (e) {
                let file = e.target.files[0];
                let reader = new FileReader();
                  const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                     if((img.height !== 400 || img.width !== 400) || (sizeInMB > 2)){
                    //  if(sizeInMB > 2)
                    // {
                            this.errImageupload = 1
                             this.$refs.updateImgInput.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 400*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$vs.loading()
      this.$store.dispatch('offer/uploadImage', {image:e.target.files[0], offer_id:this.formData['offer_id']}).then((res) => {
        this.formData['image_url_list'] = res
        localStorage.setItem('update_offer',1)
        this.remove_thumbnailsList = true;
        this.add_thumbnailList = false;
        this.$refs.updateImgInputList.value = ''
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
      }
    }, 200);
  },
      remove_imageList(e) {
                 let file = e.target.files[0];
                let reader = new FileReader();
                  const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                     if((img.height !== 400 || img.width !== 400) || (sizeInMB > 2)){
                    // if(sizeInMB > 2)
                    // {
                            this.errImageupload = 1
                             this.$refs.updateImgInput.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 400*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$vs.loading()
      this.$store.dispatch('offer/removeImage', {image:e.target.files[0], offer_id:this.formData['offer_id'],oldImage:this.formData['image_url']}).then((res)=>{
       this.formData['image_url_list'] = res
        localStorage.setItem('update_offer',1)
        this.remove_thumbnailsList = true;
        this.add_thumbnailList = false;
        this.$refs.updateImgInputList.value = ''
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
         }
    }, 200);
    },
     updateCurrImg (e) {
                let file = e.target.files[0];
                let reader = new FileReader();
                  const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                      if((img.height !== 400 || img.width !== 600) || (sizeInMB > 2)){
                    //   if(sizeInMB > 2)
                    // {
                            this.$refs.updateImgInput.value = ''
                            this.errImageupload = 1
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 600*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$vs.loading()
      this.$store.dispatch('offer/uploadImage', {image:e.target.files[0], offer_id:this.formData['offer_id']}).then((res) => {
        this.formData['image_url'] = res
         this.remove_image = true;
         this.$refs.updateImgInput.value = ''
         localStorage.setItem('update_offer',1)
         this.upload_image = 1
        this.add_image = false;
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
      }
    }, 200);
  },
     remove_images(e) { 
        let file = e.target.files[0];
                let reader = new FileReader();
                  const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                     if((img.height !== 400 || img.width !== 600) || (sizeInMB > 2)){
                    //  if(sizeInMB > 2)
                    // {
                            this.$refs.updateImgInput.value = ''
                            this.errImageupload = 1
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 600*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
       this.$vs.loading()
      this.$store.dispatch('offer/removeImage', {image:e.target.files[0], offer_id:this.formData['offer_id'],oldImage:this.formData['image_url']}).then((res)=>{
        this.formData['image_url'] = res
         this.remove_image = true;
         this.$refs.updateImgInput.value = ''
         localStorage.setItem('update_offer',1)
         this.upload_image = 1
        this.add_image = false;
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
          }
    }, 200);
    },
  },
   created () {
//    if(this.categories.length === 0) {
      this.$store.dispatch('category/fetchCategories')
  //  }
  },

}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
